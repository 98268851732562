import styled from 'styled-components';
interface Props {
  color?: any;
}

export const ComponentDiscount = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  border-radius: 3pt;
  margin-left: 7px;
  padding: 2px;
  height: 24px;
  width: auto; // 60pt
`;
export const ComponentTitle = styled.span`
  color: rgb(33, 46, 99);
  font-size: 15px;
  line-height: 18px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
`;
export const ComponentPrice = styled.span`
  color: rgb(122, 130, 162);
  font-size: 15px;
  line-height: 22.5px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
`;
export const ComponentPromoPrice = styled.span`
  color: rgb(33, 46, 99);
  font-size: 21px;
  line-height: 25.2px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
`;
export const ComponentSite = styled.span`
  color: rgb(122, 130, 162);
  font-size: 13px;
  line-height: 19.5px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
`;
export const DiscountText = styled.span`
  color: rgb(255, 255, 255);
  font-size: 11px;
  line-height: 11px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
`;