import React, {useState, useEffect, useRef} from 'react';
import Dropzone from 'react-dropzone';
import { FaTrash } from 'react-icons/fa';

import { 
  Container,
  DropContainer,
  DropContainer2,
  Label,
  InputComponent,
  CancelButton,
  Button,
  ButtonOff,
  SelectComponent,
  TagsComponent,
  TrashComponent,
  UploadMessage,
  ButtonDelTag,
  Tag,
} from './styles';
import api from '../../services/api'
import {uploadImageToStorage} from '../../Firebase/uploadImage'
import {useAuth} from '../../hooks/auth'


const NewPack = ({setIsCreate, reload, setReload, tagIndexPosition}: any) => {
  const [title, setTitle] = useState<string>();
  const [category, setCategory] = useState<string>();
  const [site, setSite] = useState<string>();
  const [tagsString, setTagsString] = useState<string>();
  const [tags, setTags] = useState<string[]>();
  const [tagClean, setTagClean] = useState<string>();
  const [image, setImage] = useState<string>();
  const [imagePreview, setImagePreview] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [templateImage, setTemplateImage] = useState<any>();
  const [templateImagePreview, setTemplateImagePreview] = useState<any>();
  const [categories, setCategories] = useState<any>();
  // const [countries, setCountries] = useState<any>();
  // const [country, setCountry] = useState<any>();
  const {country} = useAuth();

  const myStateRefTags = useRef(tags);
  const myStateRefTagString = useRef(tagsString);

  const _setTags = (dataS: any) => {
    myStateRefTags.current = dataS;
    setTags(dataS);
  };
  const _setTagsString = (dataS: string) => {
    myStateRefTagString.current = dataS;
    setTagsString(dataS);
  };

  const handlePreviewImage = async (files: any) => {
    const preview = URL.createObjectURL(files?.[0]);
    setImagePreview(preview);
    setImage(files);
  };
  const handlePreviewTemplate = async (files: any) => {
    const preview = URL.createObjectURL(files?.[0]);
    setTemplateImagePreview(preview);
    setTemplateImage(files);
  };
  const renderDragMessage = (isDragActive: any, isDragReject: any) => {
    if (!isDragActive) {
      return (
        <TrashComponent>
          <FaTrash />
        </TrashComponent>
      );
    }
    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado!</UploadMessage>;
    }
    return <UploadMessage type="success">Solte aqui ... </UploadMessage>;
  };


  const addTagsFromState = (tagStr: string, tagsRef: any, stringTagRef?: string) => {
    // const formattedTag = tagStr.replace(/[^a-zA-Z0-9]/g, '').trim();
    const formattedTag = tagStr.trim();
    const str = stringTagRef ? `${stringTagRef}#${formattedTag}` : formattedTag;
    _setTagsString(str)
    if (tagsRef) {
      const newArr = [...tagsRef];
      newArr.push(formattedTag)
      _setTags(newArr);
      setTagClean('');
    } else {
      const newArr = [];
      newArr.push(formattedTag)
      _setTags(newArr);
      setTagClean('');
    }
    
  };
  const deleteTag = (tag: string) => {
    console.log({tag})
    if (!tags) {
      return;
    }
    const newArr = [...tags]
    const index = newArr?.findIndex(t => t === tag);
    
    newArr?.splice(index, 1);
    _setTags(newArr)
    if (!tagsString) {
      return;
    }
    const str = tagsString.replace(`#${tag}`, '');
    const str2 = str.replace(`${tag}#`, '');
    _setTagsString(str2)
  }



  useEffect(() => {
    const inputElement = document.getElementById('enter');
    inputElement?.addEventListener('keyup', function(e: any){
      var key = e.which || e.keyCode;
      if (key === 13) {      
        console.log('carregou enter o valor digitado foi: ', e?.target.value);
        if(e?.target.value) {
          addTagsFromState(e?.target.value, myStateRefTags.current, myStateRefTagString.current);
        }
      }
    });
    /*eslint-disable */
  }, [])
  useEffect(()=> {
    const loadCategories = async () => {
      const response = await api.get<any>('/sections');
      setCategories(response.data);
    }
    loadCategories();
  }, [])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      if (!title) {
        alert('title is required')
        setLoading(false)
        return;
      }
      if (!templateImage) {
        alert('image is required')
        setLoading(false)
        return;
      }
      if (!image) {
        alert('logo is required')
        setLoading(false)
        return;
      } 
      const titleImg = title.replaceAll(' ', '-')
      const imageUpload = await uploadImageToStorage(templateImage, titleImg)
      const logoUpload = await uploadImageToStorage(image, `${titleImg}-logo`)

      const data = {
        countryIso: country,
        indexPosition: tagIndexPosition,
        sectionId: category !== 'NoMap' ? category : undefined,
        title,
        image: imageUpload,
        logo: logoUpload,
        tags: tagsString,
        site,
      }
      console.log(data)
      await api.post('/packs', data)
      setReload(!reload)
      setIsCreate(false)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log({
        error,
      })
    }
  }

  return (
    <Container>
      <span style={{fontSize: '15pt', fontWeight: '600', marginBottom: '3%'}}>Novo Pack</span>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
      }}>
        <div style={{
          width: '50%'
        }}>
          <div>
            {/* <Label>Country</Label>
            <SelectComponent onChange={(txt: any) => setCountry(txt.target.value)}>
              {
                countries?.map((c: any) => (
                  <option key={c.name} value={c.iso}>{c.name}</option>
                ))
              }
            </SelectComponent> */}
          </div>
          <div>
            <Label>Nome<span style={{color: '#c45'}}>*</span></Label>
            <InputComponent>
              <input
              style={{width: '100%', border: 'none', backgroundColor: 'transparent'}}
              placeholder='Pack Name'
              onChange={txt => setTitle(txt.target.value)}
              />
            </InputComponent>
          </div>
          <div>
            <Label>Categoria</Label>
            <SelectComponent onChange={(txt: any) => {setCategory(txt.target.value)}}>
              <option value={'NoMap'}>No categories</option>
              {
                categories?.map((cat: any) => (
                  <option key={cat.id} value={cat.id}>{cat.name}</option>
                ))
              }
            </SelectComponent>
          </div>
          <div>
            <Label>SITE</Label>
            <InputComponent>
              <input
              style={{width: '100%', border: 'none', backgroundColor: 'transparent'}}
              placeholder='Site'
              onChange={txt => setSite(txt.target.value)}
              value={site}
              />
            </InputComponent>
          </div>
          <div>
            <Label>Tags<span style={{color: '#c45'}}>*</span></Label>
            <TagsComponent>
              {
                tags?.map(t => (
                  <Tag key={t}>
                    <small>{t}</small>
                    <ButtonDelTag onClick={() => deleteTag(t)}>X</ButtonDelTag>
                  </Tag>
                ))
              }
              <input
              id="enter"
              style={{width: '100%', border: 'none', backgroundColor: 'transparent'}}
              placeholder=''
              onChange={txt => setTagClean(txt.target.value)}
              value={tagClean}
              />
            </TagsComponent>
          </div>
        </div>


        <div style={{
          display: 'flex',
          flexDirection: 'column',
          width: '50%'
        }}>
          <div >
          <Label>Logo<span style={{color: '#c45'}}>*</span></Label>
            <Dropzone accept="image/*" onDropAccepted={handlePreviewImage}>
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
              }) => (
                <DropContainer
                  {...getRootProps()}
                  isDragActive={isDragActive} // aceitar img
                  isDragReject={isDragReject}
                  img={imagePreview}
                >
                  <input {...getInputProps()} />
                  {renderDragMessage(isDragActive, isDragReject)}
                </DropContainer>
              )}
              </Dropzone>
          </div>
          <div style={{marginTop: '12px'}}>
          <Label>Image<span style={{color: '#c45'}}>*</span></Label>
            <Dropzone accept="image/*" onDropAccepted={handlePreviewTemplate}>
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
              }) => (
                <DropContainer2
                  {...getRootProps()}
                  isDragActive={isDragActive} // aceitar img
                  isDragReject={isDragReject}
                  img={templateImagePreview}
                >
                  <input {...getInputProps()} />
                  {renderDragMessage(isDragActive, isDragReject)}
                </DropContainer2>
              )}
              </Dropzone>
          </div>
        </div>
      </div>

      <div style={{
        display: 'flex',
        width: '90%',
        marginRight: 'auto',
        marginLeft: 'auto',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 12,
      }}>
        <CancelButton onClick={() => setIsCreate(false)}>
          Cancelar
        </CancelButton>
        {
          !loading && (
            <Button onClick={() => handleSubmit()}>
              Criar Pack
            </Button>
          )
        }
        {
          loading && (
            <ButtonOff>
              Criar Pack
            </ButtonOff>
          )
        }
        
      </div>
    </Container>
  );
}

export default NewPack;