import React, {useState, useEffect} from 'react';
import {RiHome6Line} from 'react-icons/ri';
import {FaUserPlus, FaTrash, FaEdit} from 'react-icons/fa';
import { deepOrange } from '@mui/material/colors';
import TablePagination from '@mui/material/TablePagination';

import ModalPack from '../../components/NewPackModal';
import Header from '../../components/Header';
import banner from '../../assets/banner.png';
import icon from '../../assets/icon.png';
import Card from '../../components/CardOffers';
import { useParams } from 'react-router-dom'
import Avatar from '@mui/material/Avatar';
import api from '../../services/api';
// import {useAuth} from '../../hooks/auth';
import {
  Input,
  Button,
  ContainerHome,
  UsersComponent,
  ActionsComponent,
  SmallComponent,
  SpanComponent,
  ColorComponent,
  SpanComponentOffers,
  SmallComponentOffers,
} from './styles';


const Home = () => {
  const [isCreate, setIsCreate] = useState(false);
  const [offers, setOffers] = useState([]);
  const [page, setPageParam] = useState(1);
  const [qtyPerPage, setQtyPerPage] = useState(6);
  const [hasMoreData, setHasMoreData] = useState(false);

  const {id, name, email, avatar} = useParams();
  const user = {
    id,
    name,
    email,
    avatar,
  }
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPageParam(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setQtyPerPage(parseInt(event.target.value, 10));
    setPageParam(0);
  };
  const updateOffers = () => {
    if(page === 0) {
      setPageParam(1)
    } else {
      setPageParam(0)
    }
    
  }

  useEffect(() => {
    const loadData = async () => {
      const response = await api.get(`/products/favorite-offers/${id}`, {
        params: {
          createdByUser: 'yes',
          page,
          qtyPerPage,
        }
      });
      setOffers(response.data.data);
      setHasMoreData(response.data.hasMoreData);
    }
    loadData();
  }, [page, qtyPerPage, id])
    const containerElement = document.getElementById("container");
    // const { onChangeCountry, country} = useAuth();

  
  //   const handleEdit = (dt: any) => {
  //     console.log({
  //       edit: dt,
  //     })
  //   };

  const handleDelete = async () => {
    try {
      const accept = window.confirm("Are you sure you want to delete this user?")
      if (accept) {
        await api.delete(`/users/${id}`)
      }
    } catch (error) {
      console.log({
        error,
      })
    }
  };

  console.log(hasMoreData)
  const handleClickOutside = () => {
    setIsCreate(false);
    containerElement?.removeEventListener('click', handleClickOutside, false);
    console.log('click fora')
  }
  containerElement?.addEventListener('click', handleClickOutside, false);


  let sizesComponents: any = {
    1: 'calc(26%)',
    2: 'calc(50%) calc(50%) calc(20%)',
    3: 'calc(35%) calc(35%) calc(35%) calc(45%)',
    4: 'calc(25%) calc(25%) calc(25%) calc(25%) calc(56%)',
    5: 'calc(21%) calc(21%) calc(21%) calc(21%) calc(21%) calc(60%)',
    6: 'calc(18%) calc(18%) calc(18%) calc(18%) calc(18%) calc(18%) calc(70%)',
    7: 'calc(15%) calc(15%) calc(15%) calc(15%) calc(15%) calc(15%) calc(15%) calc(77%)',
    8: 'calc(15%) calc(15%) calc(15%) calc(15%) calc(15%) calc(15%) calc(15%) calc(15%) calc(77%)',
    9: 'calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%)',
    10: 'calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%)',
    11: 'calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%)',
  }
  const nm  = offers?.length / 3;
  const numberInt = parseInt(String(nm), 10);
  const numberFormat = nm > numberInt ? numberInt + 1: nm;


    return (
      <>
      <ContainerHome img={banner} data={user} id="container">
        <Header setPage={'users'} />
        <div style={{ width: '80%', marginRight: 'auto', marginLeft: 'auto'}}>
          <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 200
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '77%',
            }}>
              <span style={{color: '#fff', fontSize: 27}}>Detalhes do usuário</span>
              <div style={{opacity: 0.7, display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 7}}>
                <RiHome6Line color={'#ddd'} size={20} />
                <li style={{color: '#ddd', fontSize: 15, marginLeft: 7}}>Ofertas</li>
              </div>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}>
              <Input />
              <Button onClick={() => {
                setIsCreate(true);
              }}>
                <FaUserPlus size={19} color="#fff" />
                <span style={{color: '#fff', fontSize: 14, fontWeight: '600', marginLeft: 7}}>Novo Usuário</span>
              </Button>
            </div>
          </div>
          <UsersComponent>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
            }}>
              {
                    user?.avatar && user?.avatar !== "undefined" ? (
                      <Avatar
                        variant="rounded"
                        alt="offlet"
                        src={user?.avatar}
                        sx={{ width: 124, height: 145 }}
                      />
                    ):(
                      <Avatar sx={{ bgcolor: deepOrange[500], width: 124, height: 145 }} variant="rounded" alt="offlet">
                        {
                          user?.name && user.name.charAt(0).toUpperCase()
                        }
                      </Avatar>
                    )
                  }
              <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginLeft: 17
                }}>
                <SpanComponent>{user?.name}</SpanComponent>
                <div style={{marginBottom: '40px'}}>
                  {/* <SmallComponent>{user?.address}</SmallComponent> */}
                  {/* <SmallComponent> . </SmallComponent> */}
                  <SmallComponent>{user?.email}</SmallComponent>
                  {/* <SmallComponent> . </SmallComponent>
                  <SmallComponent>{user?.id}</SmallComponent> */}
                </div>

                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  border: 'dashed 1.5px #B5B5C3',
                  borderRadius: 4,
                  opacity: 0.8,
                  padding: 10,
                  width: '100%',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginBottom: 2,
                }}>
                <Avatar
                  variant="rounded"
                  alt="Remy Sharp"
                  src={icon}
                  sx={{ width: 34, height: 34 }}
                />
                  <div style={{display: 'flex', flexDirection: 'column', marginLeft: '8px', justifyContent: 'space-between'}}>
                    <SpanComponentOffers>{offers.length}</SpanComponentOffers>
                    <SmallComponentOffers>Ofertas resgatadas</SmallComponentOffers>
                  </div>
                </div>
              </div>
            </div>
            <ActionsComponent>
              <ColorComponent>
                <FaEdit size={17} color="#212E63" />
              </ColorComponent>
              <ColorComponent onClick={handleDelete}>
                <FaTrash size={17} color="#E83C4D" />
              </ColorComponent>
            </ActionsComponent>
            
          </UsersComponent>
          <TablePagination
            component="div"
            count={8000}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={qtyPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[3, 6, 9, 12]}
          />
          <div style={{
            marginTop: 12,
            display: 'grid',
            gridTemplateRows: sizesComponents[numberFormat],
            gridTemplateColumns: 'auto auto auto',
           
            gridColumnGap: '1px',
            gridRowGap: '1px',
          }}>
          {
            offers && offers.map((o: any) => (
              <Card offer={o} updateOffers={updateOffers} />
            ))
          }
          </div>
          
          
        </div>
      </ContainerHome>
      {
        isCreate && <ModalPack setIsCreate={setIsCreate} />
      }
      </>
    );
};
export default Home;
