import React, {useState, useEffect} from 'react';
import {RiHome6Line} from 'react-icons/ri';
import {FaEdit} from 'react-icons/fa';
import {GoSearch} from 'react-icons/go';
import Header from '../../components/Header';
import banner from '../../assets/banner.png';
import api from '../../services/api';
import {
  Input,
  Button,
  Button2,
  ContainerHome,
  ModalSelectCountryDiv,
  SelectSpan,
  SelectDiv,
  CancelButton,
  ColorComponent,
  CategoryContainer,
  CategoryList,
  Category,
  InputCategory,
} from './styles';

const Home = () => {
  const [sections, setSections] = useState<any>();
  const [sections2, setSections2] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [isNewCategory, setIsNewCategory] = useState(false);
  const [filter, setFilter] = useState();
  const [cat, setCat] = useState<any>();

  const containerElement = document.getElementById("container");
 


  useEffect(()=> {
    const loadData = async () => {
      const responseSections = await api.get<any>('/sections');
      setSections(responseSections.data)
    }
    loadData();
  }, [reload])


  const ModalCreateCategory = () => {
    const [categoryLabel, setCategoryLabel] = useState<string>();
    const [weight, setWeight] = useState<number>();
    const handleSubmitCategory = async () => {
      try {
        await api.post('/sections', {
          name: categoryLabel,
          weight,
        })
        setIsNewCategory(false)
        setLoading(false)
      } catch (error) {
        alert(error)
        setLoading(false)
      }
    }
    return (
      <ModalSelectCountryDiv>
        <SelectSpan>Nova Categoria</SelectSpan>
        <div style={{
          width: '90%',
        }}>
          <SelectDiv>
            <div style={{
              marginBottom: '7px',
              }}>
            <label style={{color: 'rgb(94 98 120)', fontWeight: 'bold', marginRight: '3px'}}>Nome</label>
            <label style={{color: '#c45'}}>*</label>
            </div>
            
            <InputCategory
            type="text"
            onChange={(value: any) => setCategoryLabel(value.target.value)}
            placeholder={'Digite..'}
            value={categoryLabel}
            />
             <div style={{
              marginBottom: '7px',
              }}>
            <label style={{color: 'rgb(94 98 120)', fontWeight: 'bold', marginRight: '3px'}}>Peso</label>
            <label style={{color: '#c45'}}>*</label>
            </div>
            
            <InputCategory
            type="number"
            onChange={(value: any) => setWeight(value.target.value)}
            placeholder={'Digite o peso..'}
            value={weight}
            />
          </SelectDiv>
          <div style={{
            display: 'flex',
            width: '90%',
            marginTop: '4%',
            marginRight: 'auto',
            marginLeft: 'auto',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <CancelButton onClick={() => setIsNewCategory(false)}>
            Cancelar
          </CancelButton>
          {
            !loading && (
              <Button2 onClick={() => {
                setLoading(true)
                handleSubmitCategory();
              }}>
                Criar Categoria
              </Button2>
            )
          }
          {
            loading && (
              <Button2 onClick={() => {
              }}>
                Criar Categoria
              </Button2>
            )
          }
          
        </div>
        </div>
      </ModalSelectCountryDiv>
    );
  }

  const ModalUpdateCategory = () => {
    const [categoryLabel, setCategoryLabel] = useState<string>(cat.name);
    const [weight, setWeight] = useState<number>(cat?.weight ? cat.weight: 0);
    const handleSubmitCategory = async () => {
      try {
        await api.put(`/sections/${cat.id}`, {
          name: categoryLabel,
          weight: Number(weight),
        })
        setCat(null)
        setLoading(false)
        setReload(!reload)
      } catch (error) {
        alert(error)
        setLoading(false)
      }
    }
    return (
      <ModalSelectCountryDiv>
        <SelectSpan>Editar Categoria</SelectSpan>
        <div style={{
          width: '90%',
        }}>
          <SelectDiv>
            <div style={{
              marginBottom: '7px',
              }}>
            <label style={{color: 'rgb(94 98 120)', fontWeight: 'bold', marginRight: '3px'}}>Nome</label>
            <label style={{color: '#c45'}}>*</label>
            </div>
            
            <InputCategory
            type="text"
            onChange={(value: any) => setCategoryLabel(value.target.value)}
            placeholder={'Digite..'}
            value={categoryLabel}
            />
             <div style={{
              marginBottom: '7px',
              }}>
            <label style={{color: 'rgb(94 98 120)', fontWeight: 'bold', marginRight: '3px'}}>Peso</label>
            <label style={{color: '#c45'}}>*</label>
            </div>
            
            <InputCategory
            type="number"
            onChange={(value: any) => setWeight(value.target.value)}
            placeholder={'Digite o peso..'}
            value={weight}
            />
          </SelectDiv>
          <div style={{
            display: 'flex',
            width: '90%',
            marginTop: '4%',
            marginRight: 'auto',
            marginLeft: 'auto',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <CancelButton onClick={() => setCat(null)}>
            Cancelar
          </CancelButton>
          {
            !loading && (
              <Button2 onClick={() => {
                setLoading(true)
                handleSubmitCategory();
              }}>
                Editar Categoria
              </Button2>
            )
          }
          {
            loading && (
              <Button2 onClick={() => {
              }}>
                Editar Categoria
              </Button2>
            )
          }
          
        </div>
        </div>
      </ModalSelectCountryDiv>
    );
  }


  useEffect(() => {
    if (sections) {
      const existUser = (usr: any) => {
        const key = usr?.name?.toLowerCase().search(filter);
        if (key !== -1 && usr?.name) {
          return usr
        }
      }
      const sectionsFilters = sections.filter(existUser)
      setSections2(sectionsFilters)
    }
  }, [filter, sections])

  
  const handleClickOutside = () => {
    containerElement?.removeEventListener('click', handleClickOutside, false);
    console.log('click fora')
  }
  containerElement?.addEventListener('click', handleClickOutside, false);
    return (
      <>
      <ContainerHome img={banner} data={sections} id="container">
        <Header setPage={'categories/internal'} />
        <div style={{ width: '80%', marginRight: 'auto', marginLeft: 'auto'}}>
          <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 200
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '77%',
            }}>
              <span style={{color: '#fff', fontSize: 27}}>Categorias</span>
              <div style={{opacity: 0.7, display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 7}}>
                <RiHome6Line color={'#ddd'} size={20} />
                <li style={{color: '#ddd', fontSize: 15, marginLeft: 7}}>Categorias</li>
              </div>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}>
              < div/>
              < div/>
              
              <Button onClick={() => {
                setIsNewCategory(true);
              }}>
                <span style={{color: '#fff', fontSize: 30, marginRight: 7}}>+</span>
                <span style={{color: '#fff', fontSize: 14, fontWeight: '600'}}>Nova Categoria</span>
              </Button>
            </div>
          </div>
          <CategoryContainer>
            <Input>
              <input
              placeholder="Buscar categoria"
              type="text"
              onChange={(value: any) => {setFilter(value.target.value)}}
              />
              <GoSearch />
            </Input>
            {
              !filter && sections && sections.map((cat: any) => (
                <CategoryList key={cat.id}>
                  <Category>{cat.name}</Category>
                  <ColorComponent onClick={() => setCat(cat)} style={{backgroundColor: 'rgb(224, 222, 242)'}}>
                    <FaEdit size={17} color="#212E63" />
                  </ColorComponent>
                </CategoryList>
              ))
            }
            {
              filter && sections2 && sections2.map((cat: any) => (
                <CategoryList key={cat.id}>
                  <Category>{cat.name}</Category>
                  <ColorComponent onClick={() => setCat(cat)} style={{backgroundColor: 'rgb(224, 222, 242)'}}>
                    <FaEdit size={17} color="#212E63" />
                  </ColorComponent>
                </CategoryList>
              ))
            }
            
          </CategoryContainer>
        </div>
        {
          isNewCategory && <ModalCreateCategory />
        }
        {
          cat && <ModalUpdateCategory />
        }   
      </ContainerHome>
      </>
    );
};
export default Home;
