import firebase from 'firebase';
require('firebase/storage');

const config = {
  apiKey: "AIzaSyB4_9eFdTBcOn-iEMEtf_EJisfcMh4qvAo",
  authDomain: "off-let-f8e5a.firebaseapp.com",
  projectId: "off-let-f8e5a",
  storageBucket: "off-let-f8e5a.appspot.com",
  messagingSenderId: "523938711112",
  appId: "1:523938711112:web:3ddfcc34a7e103263dba7d",
  measurementId: "G-ZL7BWWKYKF"
};

if(!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app();
}
firebase.auth().useDeviceLanguage();


const storage = firebase.storage();
const auth = firebase.auth();

export {
  storage,
  auth,
};