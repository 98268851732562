import styled from 'styled-components';
interface Props {
  img?: any;
  data?: any;
}

export const ContainerHome = styled.div<Props>`
  width: 100%;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: 100% ${(props) => {
    if(props.data) {
      return '400px'
    } else {
      return '300px'
    }
  }};
  background-position: top;
  background-color: #f8f8fa;
`;

export const Input = styled.div`
  background-color: #fff;
  margin-right: 10px;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6pt;
  /* margin-right: 0; */
  width: 60%;
  &:active {
    background-color: #fbfbfc;
    border-radius: 3px;
  }
  input {
    background: transparent;
    border: 0;
    box-shadow: none;
    color: #272727;
    font-size: 16px;
    line-height: 1.5em;
    margin: 0;
    outline: none;
    padding: 8px 16px;
    width: 100%;
  }
  padding-right: 32px;
`;

export const Button = styled.button`
  color: '#fff';
  background-color: rgb(232 60 77);
  padding: 3px 13px 3px 13px;
  border-radius: 6pt;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
`;
export const TextSpan = styled.span`
  color: rgb( 33 46 99);
  font-weight: bold;
  font-size: 14px;
  margin-left: 2%;
`;
export const CancelButton = styled.button`
  color: rgb(94 98 120);
  font-weight: bold;
  font-size: 18px;
  border: none;
  background-color: transparent;
`;
export const Button2 = styled.button`
  background-color: rgb( 33 46 99);
  border-radius: 4pt;
  border: none;
  padding: 10px 16px;
  font-weight: bold;
  color: #fff;
`;
export const UsersComponent = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 6px;
  margin-left: auto;
  margin-top: 20px;
  margin-right: auto;
  background-color: #fff;
  padding: 20px;
  padding-top: 50px;
`;
export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 6px;
`;
export const InfoHeaderComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
`;
export const UserComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: solid 0.4px rgb(229, 234, 238);
  border-bottom: solid 0.2px rgb(229, 234, 238);
  align-items: center;
  padding: 10px;
`;
export const InfoComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 14px;
  width: 100%;
`;
export const ActionsComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const SpanComponent = styled.span`
  color: rgb(24, 28, 50);
  font-size: 12pt;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
`;
export const SmallComponent = styled.small`
  color: rgb(181, 181, 195);
  font-size: 10pt;
  margin-top: 4px;
  font-family: 'Poppins', sans-serif;
`;
export const RollComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 18%;
  align-items: center;
`;
export const RollComponentInfos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
`;
export const ColumnComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
export const ColorComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  background-color: rgb(253, 237, 238);
  margin-left: 4px;
  border-radius: 6px;
`;
export const SpanComponentHeader = styled.span`
  color: rgb(181, 181, 195);
  font-size: 11pt;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
`;