import React, {useState, useEffect} from 'react';
import {RiHome6Line} from 'react-icons/ri';
import {GoSearch} from 'react-icons/go';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper'
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import { Draggable } from "react-drag-reorder";
import ModalPack from '../../components/NewPackModal';
import CardTest from '../CardTest/Card';
import Header from '../../components/Header';
import br_logo from '../../assets/br.png';
import usa_logo from '../../assets/usa.svg';
import banner from '../../assets/banner.png';
import {useAuth} from '../../hooks/auth';
import EditPack from '../../components/EditPackModal';
import api from '../../services/api';
import {
  Input,
  Button,
  Button2,
  ContainerHome,
  ModalSelectCountryDiv,
  SelectSpan,
  TextSpan,
  SelectDiv,
  CancelButton,
} from './styles';

const Home = () => {
  const [packs, setPacks] = useState<any>();
  const [dataFromEdit, setDataFromEdit] = useState<any>();
  const [filter, setFilter] = useState();
  const [isCreate, setIsCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isosFromReplicate, setIsosFromReplicate] = useState<any>();
  const [isSelectCountry, setIsSelectCountry] = useState(false);
  const [reload, setReload] = useState(false);
  const [newDuplicateData, setDuplicate] = useState<any>();
  const [countries, setCountries] = useState<any>();
  console.log({
    filter,
    newDuplicateData,
  });
  const containerElement = document.getElementById("container");
 
  const countryLogos: any = {
    'USA': usa_logo,
    'BRA': br_logo,
  };

  const { onChangeCountry, country } = useAuth();
  console.log({country})

  useEffect(()=> {
    const loadData = async () => {
      const responseCountry = await api.get<any>('/countries');
      const responsePacks = await api.get<any>('/packs', {
        params: {
          countryIso: country ? country : 'USA',
          isAdmin: true,
        }
      });
      setPacks(responsePacks.data.data)
      setCountries(responseCountry.data);
    }
    loadData();
  }, [country, reload])

  const handleSubmitDuplicate = async () => {
    
    const promise = isosFromReplicate.map(async (c: any) => {
      const data = {
        countryIso: c.iso,
        indexPosition: 1,
        sectionId: newDuplicateData.sectionId ? newDuplicateData.sectionId : undefined,
        title: newDuplicateData.title,
        image: newDuplicateData.image,
        logo: newDuplicateData.logo,
        tags: newDuplicateData.tags ? newDuplicateData.tags: undefined,
        site: newDuplicateData.site ? newDuplicateData.site: undefined,
      }
      await api.post('/packs', data)
    })
    await Promise.all(promise)
    setIsosFromReplicate(null)
    setLoading(false)
    setIsSelectCountry(false)
  }

  const ModalSelectCountry = (props: any) => {
    return (
      <ModalSelectCountryDiv>
        <SelectSpan>Selecione os Países para replicar o Pack.</SelectSpan>
        <div style={{
          width: '90%',
        }}>
          {
            countries?.map((c: any) => (
              <SelectDiv>
                <input type="checkbox" onChange={(e) => {
                  if (e.currentTarget.checked) {
                    if (isosFromReplicate) {
                      const newArr = [...isosFromReplicate];
                      newArr.push(c)
                      setIsosFromReplicate(newArr)
                      console.log(newArr)
                    } else {
                      const newArr = [c];
                      setIsosFromReplicate(newArr)
                      console.log({newArr})
                    }
                  } else {
                    const newArr = [...isosFromReplicate];
                    const index = isosFromReplicate.findIndex((i: any) => i.iso === c.iso);
                    newArr.splice(index, 1);
                    setIsosFromReplicate(newArr)
                    console.log(newArr)
                  }
                  
                }} checked={isosFromReplicate?.some((i: any) => i.iso === c.iso)} />
                <TextSpan>{c.name}</TextSpan>
              </SelectDiv>
            ))
          }
          <div style={{
          display: 'flex',
          width: '90%',
          marginTop: '4%',
          marginRight: 'auto',
          marginLeft: 'auto',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <CancelButton onClick={() => setIsSelectCountry(false)}>
            Cancelar
          </CancelButton>
          {
            !loading && (
              <Button2 onClick={() => {
                handleSubmitDuplicate()
                setLoading(true)
              }}>
                Replicar
              </Button2>
            )
          }
          {
            loading && (
              <Button2 onClick={() => {
                handleSubmitDuplicate()
              }}>
                Replicando..
              </Button2>
            )
          }
          
        </div>
        </div>
      </ModalSelectCountryDiv>
    );
  }
  const handleEdit = (dt: any) => {
    console.log({
      edit: dt,
    })
    setDataFromEdit(dt)
    setIsEdit(true)
  };
  const handleDuplicate = (dt: any) => {
    setIsSelectCountry(true);
    setDuplicate(dt);
    // api.post(dt);
    // const newArr = [...packs];
    // controlR++;
    // const newdt = {...dt, _id: `${dt._id}${controlR}`}
    // newArr.push(newdt);
    // setPacks(newArr);

  };
  const handleHide = async (dt: any) => {
    try {
      await api.put(`/packs/${dt.id}`, {isHide: true})
      setReload(!reload)
    } catch (error: any) {
      alert(error)
    }
  };
  const handleHideOff = async (dt: any) => {
    try {
      await api.put(`/packs/${dt.id}`, {isHide: false})
      setReload(!reload)
    } catch (error: any) {
      alert(error)
    }
  };

  const handleDelete = async (dt: any) => {
    try {
      await api.delete(`/packs/${dt.id}`)
      setReload(!reload)
    } catch (error: any) {
      alert(error)
    }
  };


  const moveCard = async (dragIndex: number, hoverIndex: number, id: string) => {
    console.log({
      dragIndex,
      hoverIndex,
      id,
      packssIdSecundary: packs[dragIndex].id
    })
    const dragCard = packs[dragIndex]
    const newPacks: any = update(packs, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    })
    const promises = newPacks.map(async (p: any, index: number) => {
      await api.put(`/packs/${p.id}`, {indexPosition: index})
    })
    await Promise.all(promises)
    // await api.put(`/packs/${id}`, {indexPosition: hoverIndex})
    // await api.put(`/packs/${packs[dragIndex].id}`, {indexPosition: hoverIndex})
    
    setPacks(
      update(packs, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      }),
    )
  }

  let sizesComponents: any = {
    1: 'calc(26%)',
    2: 'calc(50%) calc(50%) calc(20%)',
    3: 'calc(35%) calc(35%) calc(35%) calc(45%)',
    4: 'calc(25%) calc(25%) calc(25%) calc(25%) calc(56%)',
    5: 'calc(21%) calc(21%) calc(21%) calc(21%) calc(21%) calc(60%)',
    6: 'calc(18%) calc(18%) calc(18%) calc(18%) calc(18%) calc(18%) calc(70%)',
    7: 'calc(15%) calc(15%) calc(15%) calc(15%) calc(15%) calc(15%) calc(15%) calc(77%)',
    8: 'calc(15%) calc(15%) calc(15%) calc(15%) calc(15%) calc(15%) calc(15%) calc(15%) calc(77%)',
    9: 'calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%)',
    10: 'calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%)',
    11: 'calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%) calc(35%)',
  }
  const nm  = packs?.length / 3;
  const numberInt = parseInt(String(nm), 10);
  const numberFormat = nm > numberInt ? numberInt + 1: nm;

  
  const handleClickOutside = () => {
    setIsCreate(false);
    setIsEdit(false)
    containerElement?.removeEventListener('click', handleClickOutside, false);
    console.log('click fora')
  }
  containerElement?.addEventListener('click', handleClickOutside, false);
    return (
      <>
      <ContainerHome img={banner} data={packs} id="container">
        <Header setPage={'home'} />
        <div style={{ width: '80%', marginRight: 'auto', marginLeft: 'auto'}}>
          <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 200
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '77%',
            }}>
              <span style={{color: '#fff', fontSize: 27}}>Packs Home</span>
              <div style={{opacity: 0.7, display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 7}}>
                <RiHome6Line color={'#ddd'} size={20} />
                <li style={{color: '#ddd', fontSize: 15, marginLeft: 7}}>Packs Home</li>
              </div>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}>
              <Input>
                <input
                placeholder="Buscar Pack"
                type="text"
                onChange={(event: any) => setFilter(event.target.value)}
                />
                <GoSearch />
              </Input>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <img src={countryLogos[country]} style={{height: 20, marginRight: 7}} alt='offlet'/>
                <select 
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  fontWeight: '600',
                  color: '#fff'
                }}
                onChange={(value: any) => {onChangeCountry(value.target.value)}}
                defaultValue={country}
                >
                  <option>{country}</option>
                  {
                    countries && countries.map((c: any) => (
                      <option value={c.iso}>{c.iso}</option>
                    ))
                  }
                </select>
              </div>
              <Button onClick={() => {
                setIsCreate(true);
              }}>
                <span style={{color: '#fff', fontSize: 30, marginRight: 7}}>+</span>
                <span style={{color: '#fff', fontSize: 14, fontWeight: '600'}}>Novo pack</span>
              </Button>
            </div>
          </div>
          <DndProvider backend={HTML5Backend}>
              <div style={{
                  marginTop: 12,
                  display: 'grid',
                  gridTemplateRows: sizesComponents[numberFormat],
                  gridTemplateColumns: 'auto auto auto',
                  overflowY: 'scroll',
                  gridColumnGap: '1px',
                  gridRowGap: '1px',
                }} >
                {
                  packs?.map((dt: any, i: number) => {
                    return (
                    <CardTest
                      key={dt.id}
                      index={i}
                      id={dt.id}
                      text={dt.title}
                      data={dt}
                      handleEdit={handleEdit}
                      handleDuplicate={handleDuplicate}
                      handleHide={handleHide}
                      handleDelete={handleDelete}
                      moveCard={moveCard}
                      handleHideOff={handleHideOff}
                      />)
                  })
                }
              </div>
          </DndProvider>
        </div>
        {
          isSelectCountry && <ModalSelectCountry />
        }    
      </ContainerHome>
      {
        isCreate && <ModalPack setIsCreate={setIsCreate} setReload={setReload} reload={reload} tagIndexPosition={ packs ? packs.length : 0} />
      }
      {
          isEdit && (
            <EditPack setIsEdit={setIsEdit} reload={reload} setReload={setReload} data={dataFromEdit} />
          )
        }
      </>
    );
};
export default Home;
