import React, {useState, useEffect} from 'react';
import {RiHome6Line} from 'react-icons/ri';
import {GoSearch} from 'react-icons/go';
import Header from '../../components/Header';
import br_logo from '../../assets/br.png';
import usa_logo from '../../assets/usa.svg';
import banner from '../../assets/banner.png';
import {useAuth} from '../../hooks/auth';
import api from '../../services/api';
import {
  Input,
  Button,
  Button2,
  ContainerHome,
  ModalSelectCountryDiv,
  SelectSpan,
  SelectDiv,
  CancelButton,
  HeaderTitle,
  TitleContainer,
  CategoryContainer,
  Title,
  CategoryList,
  Category,
  Select,
  SelectHeader,
  InputCategory,
} from './styles';

const Home = () => {
  const [externalIntegrations, setExternalIntegrations] = useState<any>();
  const [apisExternalIntegrations, setApisExternalIntegrations] = useState<any>();
  const [apisExternalIntegrationsCurrent, setApisExternalIntegrationsCurrent] = useState<string>();
  const [sections, setSections] = useState<any>();
  const [externalIntegrations2, setExternalIntegrations2] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [isNewCategory, setIsNewCategory] = useState(false);
  const [countries, setCountries] = useState<any>();
  const [filter, setFilter] = useState();

  const containerElement = document.getElementById("container");
 
  const countryLogos: any = {
    'USA': usa_logo,
    'BRA': br_logo,
    'POR': br_logo,
    'ARG': usa_logo,
  };

  const { onChangeCountry, country} = useAuth();

  useEffect(()=> {
    const loadData = async () => {
      const responseCountry = await api.get<any>('/countries');
      const responseApisExternal = await api.get<any>('/external-integrations');
      setApisExternalIntegrations(responseApisExternal.data.externalIntegrations)
      const responseExternalIntegrations = await api.get<any>('/external-integrations/sections', {
        params: {
          externalIntegrationId: apisExternalIntegrationsCurrent 
          ? apisExternalIntegrationsCurrent
          : responseApisExternal.data.externalIntegrations[0].id
        }
      });
      const responseSections = await api.get<any>('/sections');
      setSections(responseSections.data)
      setExternalIntegrations(responseExternalIntegrations.data.externalIntegrationSectionsMapping)
      setCountries(responseCountry.data);
    }
    loadData();
  }, [country, apisExternalIntegrationsCurrent])


  const ModalCreateCategory = () => {
    const [categoryLabel, setCategoryLabel] = useState<string>();
    const [weight, setWeight] = useState<number>();
    const handleSubmitCategory = async () => {
      try {
        await api.post('/sections', {
          name: categoryLabel,
          weight,
        })
        setIsNewCategory(false)
        setLoading(false)
      } catch (error) {
        alert(error)
        setLoading(false)
      }
    }
    return (
      <ModalSelectCountryDiv>
        <SelectSpan>Nova Categoria</SelectSpan>
        <div style={{
          width: '90%',
        }}>
          <SelectDiv>
            <div style={{
              marginBottom: '7px',
              }}>
            <label style={{color: 'rgb(94 98 120)', fontWeight: 'bold', marginRight: '3px'}}>Nome</label>
            <label style={{color: '#c45'}}>*</label>
            </div>
            
            <InputCategory
            type="text"
            onChange={(value: any) => setCategoryLabel(value.target.value)}
            placeholder={'Digite..'}
            value={categoryLabel}
            />
             <div style={{
              marginBottom: '7px',
              }}>
            <label style={{color: 'rgb(94 98 120)', fontWeight: 'bold', marginRight: '3px'}}>Peso</label>
            <label style={{color: '#c45'}}>*</label>
            </div>
            
            <InputCategory
            type="number"
            onChange={(value: any) => setWeight(value.target.value)}
            placeholder={'Digite o peso..'}
            value={weight}
            />
          </SelectDiv>
          <div style={{
            display: 'flex',
            width: '90%',
            marginTop: '4%',
            marginRight: 'auto',
            marginLeft: 'auto',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <CancelButton onClick={() => setIsNewCategory(false)}>
            Cancelar
          </CancelButton>
          {
            !loading && (
              <Button2 onClick={() => {
                setLoading(true)
                handleSubmitCategory();
              }}>
                Criar Categoria
              </Button2>
            )
          }
          {
            loading && (
              <Button2 onClick={() => {
              }}>
                Criar Categoria
              </Button2>
            )
          }
          
        </div>
        </div>
      </ModalSelectCountryDiv>
    );
  }

  const handleUpdate= async (externalIntegrationSectionId: string, internalSectionId: string) => {
    console.log({
      externalIntegrationSectionId,
      internalSectionId,
    })
    await api.patch<any>('/external-integrations/sections', {
      externalIntegrationSectionId,
      internalSectionId,
    });
  }

  useEffect(() => {
    if (externalIntegrations) {
      const existUser = (usr: any) => {
        const key = usr?.externalSectionPathName?.toLowerCase().search(filter);
        if (key !== -1 && usr?.externalSectionPathName) {
          return usr
        }
      }
      const sectionsFilters = externalIntegrations.filter(existUser)
      setExternalIntegrations2(sectionsFilters)
    }
  }, [filter, externalIntegrations])

  
  const handleClickOutside = () => {
    containerElement?.removeEventListener('click', handleClickOutside, false);
    console.log('click fora')
  }
  containerElement?.addEventListener('click', handleClickOutside, false);
    return (
      <>
      <ContainerHome img={banner} data={sections} id="container">
        <Header setPage={'categories'} />
        <div style={{ width: '80%', marginRight: 'auto', marginLeft: 'auto'}}>
          <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 200
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              width: '77%',
            }}>
              <span style={{color: '#fff', fontSize: 27}}>Categorias</span>
              <div style={{opacity: 0.7, display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 7}}>
                <RiHome6Line color={'#ddd'} size={20} />
                <li style={{color: '#ddd', fontSize: 15, marginLeft: 7}}>Categorias</li>
              </div>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
            }}>
              <SelectHeader
                  onChange={(value: any) => {setApisExternalIntegrationsCurrent(value.target.value)}}
                  defaultValue={apisExternalIntegrations ? apisExternalIntegrations[0].name: ''}
                   >
                    {
                      apisExternalIntegrations && apisExternalIntegrations.map((apiExternal: any) => (
                        <option value={apiExternal.id}>{apiExternal.name}</option>
                      ))
                    }
                </SelectHeader>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <img src={countryLogos[country]} style={{height: 20, marginRight: 7}} alt='offlet'/>
                <select 
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                  fontWeight: '600',
                  color: '#fff'
                }}
                onChange={(value: any) => {onChangeCountry(value.target.value)}}
                defaultValue={country}
                >
                  <option>{country}</option>
                  {
                    countries && countries.map((c: any) => (
                      <option value={c.iso}>{c.iso}</option>
                    ))
                  }
                </select>
              </div>
              <Button onClick={() => {
                setIsNewCategory(true);
              }}>
                <span style={{color: '#fff', fontSize: 30, marginRight: 7}}>+</span>
                <span style={{color: '#fff', fontSize: 14, fontWeight: '600'}}>Nova Categoria</span>
              </Button>
            </div>
          </div>
          <CategoryContainer>
            <Input>
              <input
              placeholder="Buscar categoria"
              type="text"
              onChange={(value: any) => {setFilter(value.target.value)}}
              />
              <GoSearch />
            </Input>
            <HeaderTitle>
              <TitleContainer>
                <Title>CATEGORIAS</Title>
              </TitleContainer>
              <TitleContainer>
                <Title>EQUIVALÊNCIA</Title>
              </TitleContainer>
            </HeaderTitle>
            {
              !filter && externalIntegrations && externalIntegrations.map((cat: any) => (
                <CategoryList key={cat.id}>
                  <Category>{cat.externalSectionPathName.replaceAll('#', '')}</Category>
                  <Select
                  onChange={(value: any) => {handleUpdate(cat.id, value.target.value)}}
                  defaultValue={'Selecionar categoria'}
                   >
                     {
                       // eslint-disable-next-line
                       sections && sections.map((c: any) => {
                        if (c.id === cat.internalSectionId) {
                          return <option>{c.name}</option>
                        }
                      })
                     }
                     {
                       sections && !sections.some((catInternal: any) => catInternal.id === cat.internalSectionId) &&
                       (
                         <option>Selecionar categoria</option>
                       )
                     }
                  
                  {
                    sections && sections.map((c: any) => (
                      <option value={c.id}>{c.name}</option>
                    ))
                  }
                </Select>
                </CategoryList>
              ))
            }
            {
              filter && externalIntegrations2 && externalIntegrations2.map((cat: any) => (
                <CategoryList key={cat.id}>
                  <Category>{cat.externalSectionPathName.replaceAll('#', '')}</Category>
                  <Select
                  onChange={(value: any) => {handleUpdate(cat.id, value.target.value)}}
                  defaultValue={'Selecionar categoria'}
                   >
                     {
                       // eslint-disable-next-line
                       sections && sections.map((c: any) => {
                        if (c.id === cat.internalSectionId) {
                          return <option>{c.name}</option>
                        }
                      })
                     }
                     {
                       sections && !sections.some((catInternal: any) => catInternal.id === cat.internalSectionId) &&
                       (
                         <option>Selecionar categoria</option>
                       )
                     }
                  
                  {
                    sections && sections.map((c: any) => (
                      <option value={c.id}>{c.name}</option>
                    ))
                  }
                </Select>
                </CategoryList>
              ))
            }            
          </CategoryContainer>
        </div>
        {
          isNewCategory && <ModalCreateCategory />
        }    
      </ContainerHome>
      </>
    );
};
export default Home;
