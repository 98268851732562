import React, {useState, useEffect, useRef} from 'react';
import Dropzone from 'react-dropzone';
import { FaTrash } from 'react-icons/fa';

import { 
  Container,
  DropContainer,
  DropContainer2,
  Label,
  InputComponent,
  CancelButton,
  Button,
  ButtonDisabled,
  SelectComponent,
  TagsComponent,
  TrashComponent,
  UploadMessage,
  ButtonDelTag,
  Tag,
} from './styles';
import api from '../../services/api'
import {uploadImageToStorage} from '../../Firebase/uploadImage'

const EditPack = ({setIsEdit, reload, setReload, data}: any) => {
  const [title, setTitle] = useState<string>(data.title);
  const [category, setCategory] = useState<string>(data.sectionId);
  const [site, setSite] = useState<string>(data.site);
  const [tagsString, setTagsString] = useState<string>(data.tags);
  const [tags, setTags] = useState<string[]>();
  const [tagClean, setTagClean] = useState<string>();
  const [image, setImage] = useState<string>(data.logo);
  const [imagePreview, setImagePreview] = useState<any>(data.logo);
  const [loading, setLoading] = useState(false);
  const [templateImage, setTemplateImage] = useState<any>(data.image);
  const [templateImagePreview, setTemplateImagePreview] = useState<any>(data.image);
  const [categories, setCategories] = useState<any>();
  const [countries, setCountries] = useState<any>();

  const myStateRefTags = useRef(tags);
  const myStateRefTagString = useRef(tagsString);

  const _setTags = (dataS: any) => {
    myStateRefTags.current = dataS;
    setTags(dataS);
  };
  const _setTagsString = (dataS: string) => {
    myStateRefTagString.current = dataS;
    setTagsString(dataS);
  };

  const handlePreviewImage = async (files: any) => {
    const preview = URL.createObjectURL(files?.[0]);
    setImagePreview(preview);
    setImage(files);
  };
  const handlePreviewTemplate = async (files: any) => {
    const preview = URL.createObjectURL(files?.[0]);
    setTemplateImagePreview(preview);
    setTemplateImage(files);
  };
  const renderDragMessage = (isDragActive: any, isDragReject: any) => {
    if (!isDragActive) {
      return (
        <TrashComponent>
          <FaTrash />
        </TrashComponent>
      );
    }
    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado!</UploadMessage>;
    }
    return <UploadMessage type="success">Solte aqui ... </UploadMessage>;
  };

  let tagsControl = true

  const addTagsFromState = (tagStr: string, tagsRef?: any, tags_string?: any) => {
    const formattedTag = tagStr.trim();
    const str = tags_string ? `${tags_string}#${formattedTag}` : formattedTag;
    if (tagsControl && tagsRef?.length > 0 && data.tags) {
      _setTagsString(str)
      const newArr = [...tagsRef];
      newArr.push(formattedTag)
      _setTags(newArr);
      setTagClean('');
      tagsControl = false
      return;
    }
    _setTagsString(str)
    if (tagsRef) {
      const newArr = [...tagsRef];
      newArr.push(formattedTag)
      _setTags(newArr);
      setTagClean('');
      return;
    }
      const newArr = [];
      newArr.push(formattedTag)
      _setTags(newArr);
      setTagClean('');
  };

  const deleteTag = (tag: string) => {
    console.log({})
    if (!tags) {
      return;
    }
    const newArr = [...tags]
    const index = newArr?.findIndex(t => t === tag);
    
    newArr?.splice(index, 1);
    _setTags(newArr)
    const str = tagsString.replace(`#${tag}`, '');
    const str2 = str.replace(`${tag}#`, '');
    const str3 = str2.replace(tag, '');
    _setTagsString(str3)
  }



  useEffect(() => {
    const inputElement = document.getElementById('enter');
    inputElement?.addEventListener('keyup', function(e: any){
      var key = e.which || e.keyCode;
      if (key === 13) {      
        console.log('carregou enter o valor digitado foi: ', e?.target.value);
        if(e?.target.value) {
          console.log({
            tag: e?.target.value,
            tags: myStateRefTags.current,
            string_tags: myStateRefTagString.current,
          })
          addTagsFromState(e?.target.value, myStateRefTags.current, myStateRefTagString.current);
        }
      }
    });
  /*eslint-disable */
  }, [])
  useEffect(()=> {
    const loadCategories = async () => {
      const responseCountry = await api.get<any>('/countries');
      const response = await api.get<any>('/sections');
      setCategories(response.data);
      setCountries(responseCountry.data);
    }
    loadCategories();
    const formattedTags = data?.tags?.split('#');
    _setTagsString(data?.tags)
    _setTags(formattedTags)
  }, [data?.tags])

  const handleSubmit = async () => {
    console.log({countries})
    try {
      setLoading(true)
      if (!title) {
        alert('title is required')
        setLoading(false)
        return;
      }
      if (!templateImage) {
        alert('image is required')
        setLoading(false)
        return;
      }
      if (!image) {
        alert('logo is required')
        setLoading(false)
        return;
      } 
      const titleImg = title.replaceAll(' ', '-')
      const imageUpload = await uploadImageToStorage(templateImage, titleImg)
      const logoUpload = await uploadImageToStorage(image, `${titleImg}-logo`)
      const newTagString = tagsString?.trim();
      let cat = category !== 'NoMap' ? category : undefined;
      const data1 = {
        sectionId: cat ? cat : undefined,
        title,
        image: imageUpload,
        logo: logoUpload,
        tags: newTagString? newTagString: newTagString === "" ? "" : undefined,
        site: site ? site : undefined,
      }
      console.log(data1)
      await api.put(`/packs/${data.id}`, data1)
      setReload(!reload)
      setIsEdit(false)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log({
        error,
      })
    }
    
  }

  return (
    <Container>
      <span style={{fontSize: '15pt', fontWeight: '600', marginBottom: '3%'}}>Edit Pack</span>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
      }}>
        <div style={{
          width: '50%'
        }}>
          <div>
            <Label>Country</Label>
            <InputComponent>
              <input
              style={{width: '100%', border: 'none', backgroundColor: 'transparent'}}
              disabled
              onChange={txt => setTitle(txt.target.value)}
              value={data.countryIso}
              />
            </InputComponent>
          </div>
          <div>
            <Label>Nome<span style={{color: '#c45'}}>*</span></Label>
            <InputComponent>
              <input
              style={{width: '100%', border: 'none', backgroundColor: 'transparent'}}
              placeholder='Pack Name'
              onChange={txt => setTitle(txt.target.value)}
              value={title}
              />
            </InputComponent>
          </div>
          <div>
            <Label>Categoria</Label>
            <SelectComponent onChange={(txt: any) => setCategory(txt.target.value)}>
            <option value={'NoMap'}>{data?.category?.name || 'No categories'}</option>
              {
                categories?.map((cat: any) => (
                  <option key={cat.id} value={cat.id}>{cat.name}</option>
                ))
              }
            </SelectComponent>
          </div>
          <div>
            <Label>API</Label>
            <InputComponent>
              <input
              style={{width: '100%', border: 'none', backgroundColor: 'transparent'}}
              placeholder='Api'
              onChange={txt => setSite(txt.target.value)}
              value={site}
              />
            </InputComponent>
          </div>
          <div>
            <Label>Tags<span style={{color: '#c45'}}>*</span></Label>
            <TagsComponent>
              {
                tags?.map(t => (
                  <Tag key={t}>
                    <small>{t}</small>
                    <ButtonDelTag onClick={() => deleteTag(t)}>X</ButtonDelTag>
                  </Tag>
                ))
              }
              <input
              id="enter"
              style={{width: '100%', border: 'none', backgroundColor: 'transparent'}}
              placeholder=''
              onChange={txt => setTagClean(txt.target.value)}
              value={tagClean}
              />
            </TagsComponent>
          </div>
        </div>


        <div style={{
          display: 'flex',
          flexDirection: 'column',
          width: '50%'
        }}>
          <div >
          <Label>Logo<span style={{color: '#c45'}}>*</span></Label>
            <Dropzone accept="image/*" onDropAccepted={handlePreviewImage}>
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
              }) => (
                <DropContainer
                  {...getRootProps()}
                  isDragActive={isDragActive} // aceitar img
                  isDragReject={isDragReject}
                  img={imagePreview}
                >
                  <input {...getInputProps()} />
                  {renderDragMessage(isDragActive, isDragReject)}
                </DropContainer>
              )}
              </Dropzone>
          </div>
          <div style={{marginTop: '12px'}}>
          <Label>Image<span style={{color: '#c45'}}>*</span></Label>
            <Dropzone accept="image/*" onDropAccepted={handlePreviewTemplate}>
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
              }) => (
                <DropContainer2
                  {...getRootProps()}
                  isDragActive={isDragActive} // aceitar img
                  isDragReject={isDragReject}
                  img={templateImagePreview}
                >
                  <input {...getInputProps()} />
                  {renderDragMessage(isDragActive, isDragReject)}
                </DropContainer2>
              )}
              </Dropzone>
          </div>
        </div>
      </div>

      <div style={{
        display: 'flex',
        width: '90%',
        marginRight: 'auto',
        marginLeft: 'auto',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 12,
      }}>
        <CancelButton onClick={() => setIsEdit(false)}>
          Cancelar
        </CancelButton>
        {
          !loading && (
            <Button onClick={() => handleSubmit()}>
              Save Pack
            </Button>
          )
        }
        {
          loading && (
            <ButtonDisabled>
              Save Pack
            </ButtonDisabled>
          )
        }
        
      </div>
    </Container>
  );
}

export default EditPack;