import React from 'react';
import { Link } from 'react-router-dom'

import logo from '../../assets/logo.png';
import {useAuth} from '../../hooks/auth';

import {
  Container,
} from './styles';

interface IProps {
  setPage: string;
}
const Header = ({
  setPage = 'home',
}: IProps) => {

  const {logout} = useAuth();


  const styleNormal = {
    color: '#fff',
    fontWeight: 'normal',
    fontSize: 17,
    marginRight: 17
  }
  const styleCurrentPage = {
    color: '#fff',
    fontWeight: 'normal',
    backgroundColor: '#6c7293',
    padding: 13, borderRadius: 6,
    fontSize: 17, marginRight: 16
  }

    return (
    <Container>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '80%',
        marginRight: 'auto',
        marginLeft: 'auto',
      }}>
        
        <div style={{display: 'flex', flexDirection: 'row', width: '70%', justifyContent: 'space-between', alignItems: 'center'}}>
          <img style={{height: 60}} src={logo} alt="offlet" />
          <div style={{flex: '0 1 auto', padding: '8px', width: '70%', order: 3, gridColumn: 1/3}}>
            <small style={{color: '#fff', fontWeight: 'normal', fontSize: 17, marginRight: 17}}>Visão Geral</small>
            <Link to="/" style={setPage === 'home' ? styleCurrentPage: styleNormal}>Packs</Link>
            <Link to="/users" style={setPage === 'users' ? styleCurrentPage: styleNormal}>Usuários</Link>
            <Link to="/categories" style={setPage === 'categories' ? styleCurrentPage: styleNormal}>Categorias Externas</Link>
            <Link to="/categories/internal" style={setPage === 'categories/internal' ? styleCurrentPage: styleNormal}>Categorias Internas</Link>
        </div>
        </div>
        <div>
          <small style={{color: '#6c7293', fontWeight: '600', fontSize: 17, marginRight: 6}}>Hi, </small>
          <span style={{color: '#fff', fontWeight: '600', fontSize: 17, marginRight: 16}}>Admin</span>
          <span style={{color: '#fff', fontWeight: '600', backgroundColor: '#6c7293', padding: 17, borderRadius: 6, fontSize: 17, marginRight: 9}}>A</span>
          <button
          style={{
            border: 'none',
            backgroundColor: 'transparent',
            color: '#6c7293',
          }}
          onClick={() => {logout()}}
          >Sair</button>
        </div>

      </div>
    </Container>)
};
export default Header;
