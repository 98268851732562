import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';

import cursor from '../../assets/icon-seta.png';
import api from '../../services/api';
import {
  ComponentDiscount,
  ComponentPrice,
  ComponentPromoPrice,
  ComponentSite,
  ComponentTitle,
  DiscountText,
} from './styles';

interface IOffers {
  offer: {
    _id: string;
    favoriteOfferId: string;
    title: string;
    current_price: string;
    previous_price: string;
    previous_price_formatted: string;
    discount: number;
    images: [string];
    site: string;
  },
  updateOffers(): void;
}
export default function SimplePaper({offer, updateOffers}: IOffers) {

  const getColorToDiscountCard = (discount: number) => {
    if (discount < 30) {
      return '#ffda0a';
    }
  
    if (discount < 40) {
      return '#ffb800';
    }
  
    if (discount < 50) {
      return '#ff8311';
    }
  
    if (discount < 60) {
      return '#fd5800';
    }
  
    if (discount < 70) {
      return '#ff3403';
    }
  
    if (discount < 80) {
      return '#d0001b';
    }
  
    if (discount < 90) {
      return '#b10c21';
    }
  
    return '#930606';
  };
  const limitText = (title: string) => {
    if(title.length > 44) {
      const newtitle = title.substr(0, 44);
      const newstring = `${newtitle}...`;
      return newstring;
    } else {
      return title;
    }
  }
  
  const handleDelete = async () => {
    try {
      const accept = window.confirm("Are you sure you want to delete this offer?")
      if (accept) {
        await api.delete(`/products/favorite-offers/${offer.favoriteOfferId}`)
        updateOffers()
      }
    } catch (error) {
      console.log({
        error,
      })
    }
  };
      
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: '100%',
          height: 148,
          borderRadius: '11pt',
          padding: 2,
        },
      }}
    >
      <Paper elevation={3}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
          <div style={{
            width: 77,
            height: 77,
            borderRadius: '12px',
            marginRight: '3px',
          }}>
            <img
              alt="Remy Sharp"
              src={offer?.images[0]}
              style={{ 
                // width: 77,
                // height: 77,
                
                height: '100%',
                width: '100%',
                objectFit: 'contain',
              }}
            />

            </div>
          

          <div style={{display: 'flex', flexDirection: 'column', marginLeft: '7px'}}> 
            <ComponentTitle>{limitText(offer.title)}</ComponentTitle>
            <ComponentPrice>{offer.previous_price_formatted}</ComponentPrice>

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
              <ComponentPromoPrice>{offer.current_price}</ComponentPromoPrice>
              <ComponentDiscount color={getColorToDiscountCard(offer.discount)}>
                <DiscountText>
                  {offer.discount} % OFF
                </DiscountText>
              </ComponentDiscount>
            </div>

          </div>
          
        </div>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 4}}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <Avatar
              variant="square"
              alt="offlet"
              src={cursor}
              sx={{ width: 13, height: 13}}
            />
            <ComponentSite>{offer.site}</ComponentSite>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <ArchiveIcon sx={{color: 'rgb(94, 98, 120)', width: 20, height: 20}}/>
            <DeleteIcon onClick={() => handleDelete()} sx={{color: 'rgb(232, 60, 77)', width: 20, height: 20}}/>
          </div>
        </div>
      </Paper>
    </Box>
  );
}
