import styled from 'styled-components';
interface Props {
  img?: any;
  data?: any;
}

export const ContainerHome = styled.div<Props>`
  width: 100%;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: 100% ${(props) => {
    if(props.data) {
      return '400px'
    } else {
      return '300px'
    }
  }};
  background-position: top;
  background-color: #f8f8fa;
`;

export const Input = styled.div`
  background-color: rgb(245 248 250);
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  width: 70%;
  margin-top: 3%;
  align-items: center;
  border-radius: 6pt;
  &:active {
    background-color: #fbfbfc;
    border-radius: 3px;
  }
  input {
    background: transparent;
    border: 0;
    box-shadow: none;
    color: #272727;
    font-size: 16px;
    line-height: 1.5em;
    margin: 0;
    outline: none;
    padding: 8px 16px;
    width: 100%;
  }
  padding-right: 32px;
`;

export const Button = styled.button`
  color: '#fff';
  background-color: rgb(232 60 77);
  padding: 3px 10px 3px 10px;
  border-radius: 6pt;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
`;
export const ModalSelectCountryDiv = styled.div`
  position: absolute;
  top: 22%;
  width: 45%;
  border: solid 1px #000300;
  background-color: #fff;
  margin-left: 25%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 47px;
  @media(max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`;

export const SelectSpan = styled.span`
  color: rgb( 33 46 99);
  font-weight: bold;
  font-size: 21px;
  margin-bottom: 7%;
`;
export const TextSpan = styled.span`
  color: rgb( 33 46 99);
  font-weight: bold;
  font-size: 14px;
  margin-left: 2%;
`;

export const SelectDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 1%;
  width: 100%;
  border-bottom: solid 1px #ddd;
  border-top: solid 1px #fff;
  border-right: solid 1px #fff;
  border-left: solid 1px #fff;
`;
export const InputCategory = styled.input`
  background-color: rgb(245 248 250);
  padding: 14px;
  border-radius: 6px;
  border: none;
  margin-bottom: 34px;
`;
export const CancelButton = styled.button`
  color: rgb(94 98 120);
  font-weight: bold;
  font-size: 18px;
  border: none;
  background-color: transparent;
`;
export const Button2 = styled.button`
  background-color: rgb(232 60 77);
  border-radius: 4pt;
  border: none;
  padding: 10px 16px;
  font-weight: bold;
  color: #fff;
`;
export const CategoryContainer = styled.div`
  background-color: #fff;
  border-radius: 4pt;
  border: none;
  margin-top: 7%;
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const HeaderTitle = styled.div`
  display: flex;
  width: 70%;
  margin-top: 17px;
  flex-direction: row;
  justify-content: space-between;
`;
export const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 50%;
`;
export const Title = styled.span`
  font-weight: bold;
  font-size: 11pt;
  font-family: 'Poppins', sans-serif;
  color: rgb(181 181 195);
  margin-bottom: 18px;
`;
export const CategoryList = styled.div`
  width: 70%;
  padding: 12px 0 12px 0;
  border-top: solid 1px rgb(229 234 238);
  /* border-bottom: solid 0.1px rgb(229 234 238); */
  border-left: solid 1px #fff;
  border-right: solid 1px #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Category = styled.span`
  font-weight: bold;
  color: rgb(24 28 50);
  font-size: 12pt;
  font-family: 'Poppins', sans-serif;
  width: 50%;
`;
export const Select = styled.select`
  background-color: rgb(245 248 250);
  border: none;
  width: 45%;
  padding: 10px 73px 10px 12px;
  border-radius: 7px;
`;

export const SelectHeader = styled.select`
  background-color: rgb(245 248 250);
  border: none;
  width: 49%;
  padding: 10px 73px 10px 12px;
  border-radius: 6px;
`;