import React, {useState, useEffect} from 'react';
import {RiHome6Line} from 'react-icons/ri';
import {FaUserPlus, FaTrash, FaEdit} from 'react-icons/fa';
import {GoSearch} from 'react-icons/go';
import { Link } from 'react-router-dom'
import AvatarDefault from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';

import ModalPack from '../../components/NewPackModal';
import Header from '../../components/Header';
import banner from '../../assets/banner.png';
import api from '../../services/api';
// import {useAuth} from '../../hooks/auth';
import {
  Input,
  Button,
  Avatar,
  ContainerHome,
  UsersComponent,
  InfoHeaderComponent,
  InfoComponent,
  UserComponent,
  ActionsComponent,
  SmallComponent,
  SpanComponent,
  RollComponent,
  RollComponentInfos,
  ColorComponent,
  SpanComponentHeader,
} from './styles';

const Home = () => {
  const [filter, setFilter] = useState();
  const [isCreate, setIsCreate] = useState(false);
  const [users, setUsers] = useState<any>();
  const [users2, setUsers2] = useState<any>();
  const [reload, setReload] = useState(false);
 
  const containerElement = document.getElementById("container");
  // const { onChangeCountry, country} = useAuth();

 
  // const handleEdit = (dt: any) => {
  //   console.log({
  //     edit: dt,
  //   })
  // };

  const handleDelete = async (id: string) => {
    try {
      const accept = window.confirm("Are you sure you want to delete this user?")
      if (accept) {
        await api.delete(`/users/${id}`);
        setReload(!reload)
      }
    } catch (error) {
      console.log({
        error,
      })
    }
  };
  const handleClickOutside = () => {
    setIsCreate(false);
    containerElement?.removeEventListener('click', handleClickOutside, false);
    console.log('click fora')
  }
  containerElement?.addEventListener('click', handleClickOutside, false);
  
  useEffect(() => {
    const loadData = async () => {
      const response = await api.get('/users');
      setUsers(response.data.data);
    }
    loadData();
  }, [reload]);

  useEffect(() => {
    if (users) {
      const existUser = (usr: any) => {
        const key = usr?.name?.toLowerCase().search(filter);
        if (key !== -1 && usr?.name) {
          return usr
        }
      }
      const usersFilters = users.filter(existUser)
      setUsers2(usersFilters)
    }
  }, [filter, users])
  
  return (
    <>
    <ContainerHome img={banner} data={users} id="container">
      <Header setPage={'users'} />
      <div style={{ width: '80%', marginRight: 'auto', marginLeft: 'auto'}}>
        <div style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: 200
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '77%',
          }}>
            <span style={{color: '#fff', fontSize: 27}}>Usuários</span>
            <div style={{opacity: 0.7, display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 7}}>
              <RiHome6Line color={'#ddd'} size={20} />
              <li style={{color: '#ddd', fontSize: 15, marginLeft: 7}}>Ofertas</li>
            </div>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}>
            <Input>
              <input
              placeholder="Buscar Usuário"
              type="text"
              onChange={(event: any) => setFilter(event.target.value)}
              />
              <GoSearch />
            </Input>
            <Button onClick={() => {
              setIsCreate(true);
            }}>
              <FaUserPlus size={19} color="#fff" />
              <span style={{color: '#fff', fontSize: 14, fontWeight: '600', marginLeft: 7}}>Novo Usuário</span>
            </Button>
          </div>
        </div>
        <UsersComponent>
          <InfoHeaderComponent>
            <RollComponentInfos>
              <SpanComponentHeader>NOME</SpanComponentHeader>
            </RollComponentInfos>
            <RollComponent>
              <SpanComponentHeader>OFERTAS</SpanComponentHeader>
              <ActionsComponent>
                <SpanComponentHeader>AÇÕES</SpanComponentHeader>
              </ActionsComponent>
            </RollComponent>
            
            
            
          </InfoHeaderComponent>
          {
            !filter && users && users?.map((usr: any) => (
              <UserComponent key={usr?.email}>
                <Link to={`/users/${usr?.id}/${usr?.name}/${usr?.email}/${usr?.profilePic}`}>
                <RollComponentInfos>
                  {
                    usr?.profilePic ? (
                      <Avatar src={usr?.profilePic} alt="off-let"/>
                    ):(
                      <AvatarDefault sx={{ bgcolor: deepOrange[500] }} variant="square">
                        {
                          usr?.name?.charAt(0).toUpperCase()
                        }
                      </AvatarDefault>
                    )
                  }
                  
                  <InfoComponent>
                    <SpanComponent>{usr?.name}</SpanComponent>
                    <SmallComponent>{usr?.email}</SmallComponent>
                  </InfoComponent>
                </RollComponentInfos>
                </Link>

                <RollComponent>
                  <SpanComponent>{usr?.offers}</SpanComponent>
                  <ActionsComponent>
                    <ColorComponent style={{backgroundColor: 'rgb(224, 222, 242)'}}>
                      <FaEdit size={17} color="#212E63" />
                    </ColorComponent>
                    <ColorComponent onClick={() => handleDelete(usr?.id)}>
                      <FaTrash size={17} color="#E83C4D" />
                    </ColorComponent>
                  </ActionsComponent>

                </RollComponent>
              </UserComponent>
            ))
          }
          {
            filter && users2 && users2?.map((usr: any) => (
              <UserComponent key={usr?.email}>
                <Link to={`/users/${usr?.id}/${usr?.name}/${usr?.email}/${usr?.profilePic}`}>
                <RollComponentInfos>
                  {
                    usr?.profilePic ? (
                      <Avatar src={usr?.profilePic} alt="off-let"/>
                    ):(
                      <AvatarDefault sx={{ bgcolor: deepOrange[500] }} variant="square">
                        {
                          usr?.name?.charAt(0).toUpperCase()
                        }
                      </AvatarDefault>
                    )
                  }
                  
                  <InfoComponent>
                    <SpanComponent>{usr?.name}</SpanComponent>
                    <SmallComponent>{usr?.email}</SmallComponent>
                  </InfoComponent>
                </RollComponentInfos>
                </Link>

                <RollComponent>
                  <SpanComponent>{usr?.offers}</SpanComponent>
                  <ActionsComponent>
                    <ColorComponent style={{backgroundColor: 'rgb(224, 222, 242)'}}>
                      <FaEdit size={17} color="#212E63" />
                    </ColorComponent>
                    <ColorComponent onClick={() => handleDelete(usr?.id)}>
                      <FaTrash size={17} color="#E83C4D" />
                    </ColorComponent>
                  </ActionsComponent>

                </RollComponent>
              </UserComponent>
            ))
          }
        </UsersComponent>
        
      </div>
    </ContainerHome>
    {
      isCreate && <ModalPack setIsCreate={setIsCreate} />
    }
    </>
  );
};
export default Home;
